import React from "react";

const CryptoCounter = () => {
  const funFactContent = [
    {
      id: 1,
      meta: "100M",
      text: `Total Supply`,
    },
    {
      id: 2,
      meta: "1.5%",
      text: `Max Wallet & Tx`,
    },
    {
      id: 3,
      meta: "5%",
      text: `Buy & Sell Tax`,
    },
	    {
      id: 4,
      meta: "9",
      text: `Decimals`,
    },
  ];
  return (
    <>
      {/* <!-- Stats --> */}
      <section className="bg-light-base py-24 pb-14 dark:bg-jacarta-700">
        <div id="tokenomics" className="container">
		<h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              Tokenomics
            </h2>
					<h6 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              Smart Contract : 0x
            </h6>
          <div className="md:flex md:flex-nowrap md:space-x-6">
            {funFactContent.map((item) => (
              <div className="mb-10 text-center md:w-1/3" key={item.id}>
                <span className="mb-3 block font-display text-3xl font-semibold text-jacarta-700 dark:text-white">
                  {item.meta}
                </span>
                <span className="text-lg dark:text-jacarta-300">
                  {item.text}
                </span>
              </div>
            ))}
            {/* End single block */}
          </div>
        </div>
      </section>
      {/* <!-- end stats -->  */}
    </>
  );
};

export default CryptoCounter;
